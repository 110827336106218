/* rxlib - responsividade v2.0.1 */

@media (min-width: 397px) and (max-width: 471px) {

    /* font-size */
    body {
        font-size: 0.70rem !important;
    }

    .dropdown-menu {
        font-size: 0.70rem !important;
    }

    .form-control {
        font-size: 0.70rem !important;
    }

    .form-select {
        font-size: 0.70rem !important;
    }

    .rxlib-developer-by {
        font-size: 0.70rem !important;
    }

    /* navbar-top */
    .rxlib-navbar-nav {
        width: 5rem !important;
    }

    .navbar-nav {
        flex-direction: row !important;
    }

    .rxlib-sair {
        margin-left: 2rem !important;
    }

    .navbar-collapse {
        width: 65% !important;
    }

    .navbar-nav .dropdown-menu {
        position: absolute !important;
    }

    .nav-item {
        margin-right: 10px !important;
    }

    .collapse:not(.show) {
        display: initial !important;
    }

    .navbar-toggler {
        display: none !important;
    }

    .rxlib-usuario-logado-navbar {
        display: none !important;
    }

    /* rxlib-select-label-async */
    .rxlib-select-label-async {
        width: 100% !important;
    }

    .rxlib-select-label-async-coluna {
        width: 100% !important;
    }

    .rxlib-select-label-async-input input {
        width: 41% !important;
    }

    .rxlib-select-label-async-coluna .rxlib-select-label-async-input input {
        width: 15% !important;
    }

    .rxlib-select__control {
        height: 1.8rem !important;
        min-height: 1.8rem !important;
    }

    .rxlib-select__single-value {
        top: 41% !important;
    }

    .btn-link-select {
        width: 2.4rem !important;
        height: 2rem !important;
    }

    .btn-link-select .fs-3 {
        font-size: calc(1.17rem + .6vw) !important;
    }

    /* rxlib-input */
    .rxlib-select-label {
        width: 100% !important;
    }

    .rxlib-select-label-coluna {
        width: 100% !important;
    }

    .rxlib-select-perfil {
        width: 100% !important;
    }

    /* rxlib-table */
    .rxlib-coluna-tabela {
        display: none !important;
    }

    /* rxlib-button-crud */
    .rxlib-button-crud {
        width: 20% !important;
    }
}

@media (min-width: 472px) and (max-width: 547px) {

    /* font-size */
    body {
        font-size: 0.70rem !important;
    }

    .dropdown-menu {
        font-size: 0.70rem !important;
    }

    .form-control {
        font-size: 0.70rem !important;
    }

    .form-select {
        font-size: 0.70rem !important;
    }

    .rxlib-developer-by {
        font-size: 0.70rem !important;
    }

    /* navbar-top */
    .rxlib-navbar-nav {
        width: 5rem !important;
    }

    .navbar-nav {
        flex-direction: row !important;
    }

    .rxlib-sair {
        margin-left: 1rem !important;
    }

    .navbar-collapse {
        width: 70% !important;
    }

    .navbar-nav .dropdown-menu {
        position: absolute !important;
    }

    .nav-item {
        margin-right: 10px !important;
    }

    .collapse:not(.show) {
        display: initial !important;
    }

    .navbar-toggler {
        display: none !important;
    }

    .rxlib-usuario-logado-navbar {
        display: none !important;
    }

    /* rxlib-select-label-async */
    .rxlib-select-label-async {
        width: 100% !important;
    }

    .rxlib-select-label-async-coluna {
        width: 100% !important;
    }

    .rxlib-select-label-async-input input {
        width: 41% !important;
    }

    .rxlib-select-label-async-coluna .rxlib-select-label-async-input input {
        width: 16% !important;
    }

    .rxlib-select__control {
        height: 1.8rem !important;
        min-height: 1.8rem !important;
    }

    .rxlib-select__single-value {
        top: 41% !important;
    }

    .btn-link-select {
        width: 2.4rem !important;
        height: 2rem !important;
    }

    .btn-link-select .fs-3 {
        font-size: calc(1.15rem + .6vw) !important;
    }

    /* rxlib-input */
    .rxlib-select-label {
        width: 100% !important;
    }

    .rxlib-select-label-coluna {
        width: 100% !important;
    }

    .rxlib-select-perfil {
        width: 100% !important;
    }

    /* rxlib-table */
    .rxlib-coluna-tabela {
        display: none !important;
    }
}

@media (min-width: 548px) and (max-width: 599px) {

    /* font-size */
    body {
        font-size: 0.85rem !important;
    }

    .dropdown-menu {
        font-size: 0.85rem !important;
    }

    .form-control {
        font-size: 0.85rem !important;
    }

    .form-select {
        font-size: 0.85rem !important;
    }

    .rxlib-developer-by {
        font-size: 0.85rem !important;
    }

    /* navbar-top */
    .rxlib-navbar-nav {
        width: 5rem !important;
    }

    .navbar-nav {
        flex-direction: row !important;
    }

    .rxlib-sair {
        margin-left: 1rem !important;
    }

    .navbar-collapse {
        width: 75% !important;
    }

    .navbar-nav .dropdown-menu {
        position: absolute !important;
    }

    .nav-item {
        margin-right: 10px !important;
    }

    .collapse:not(.show) {
        display: initial !important;
    }

    .navbar-toggler {
        display: none !important;
    }

    .rxlib-usuario-logado-navbar {
        display: none !important;
    }

    /* rxlib-select-label-async */
    .rxlib-select-label-async {
        width: 70% !important;
    }

    .rxlib-select-label-async-coluna {
        width: 100% !important;
    }

    .rxlib-select-label-async-input input {
        width: 43% !important;
    }

    .rxlib-select-label-async-coluna .rxlib-select-label-async-input input {
        width: 18% !important;
    }

    .rxlib-select__control {
        height: 2rem !important;
        min-height: 2rem !important;
    }

    .rxlib-select__single-value {
        top: 41% !important;
    }

    .btn-link-select {
        width: 2.6rem !important;
        height: 2.2rem !important;
    }

    .btn-link-select .fs-3 {
        font-size: calc(1.25rem + .6vw) !important;
    }

    /* rxlib-input */
    .rxlib-select-label {
        width: 70% !important;
    }

    .rxlib-select-label-coluna {
        width: 100% !important;
    }

    .rxlib-select-perfil {
        width: 70% !important;
    }

    /* rxlib-table */
    .rxlib-coluna-tabela {
        display: none !important;
    }
}

@media (min-width: 600px) and (max-width: 663px) {

    /* font-size */
    body {
        font-size: 0.95rem !important;
    }

    .dropdown-menu {
        font-size: 0.95rem !important;
    }

    .form-control {
        font-size: 0.95rem !important;
    }

    .form-select {
        font-size: 0.95rem !important;
    }

    /* navbar-top */
    .rxlib-navbar-nav {
        width: 5rem !important;
    }

    .navbar-nav {
        flex-direction: row !important;
    }

    .rxlib-sair {
        margin-left: 1rem !important;
    }

    .navbar-collapse {
        width: 75% !important;
    }

    .navbar-nav .dropdown-menu {
        position: absolute !important;
    }

    .nav-item {
        margin-right: 10px !important;
    }

    .collapse:not(.show) {
        display: initial !important;
    }

    .navbar-toggler {
        display: none !important;
    }

    .rxlib-usuario-logado-navbar {
        display: none !important;
    }

    /* rxlib-select-label-async */
    .rxlib-select-label-async {
        width: 70% !important;
    }

    .rxlib-select-label-async-coluna {
        width: 100% !important;
    }

    .rxlib-select-label-async-input input {
        width: 43% !important;
    }

    .rxlib-select-label-async-coluna .rxlib-select-label-async-input input {
        width: 19% !important;
    }

    .rxlib-select__control {
        height: 2.1rem !important;
        min-height: 2.1rem !important;
    }

    .rxlib-select__single-value {
        top: 46% !important;
    }

    .btn-link-select {
        width: 2.6rem !important;
        height: 2.2rem !important;
    }

    .btn-link-select .fs-3 {
        font-size: calc(1.25rem + .6vw) !important;
    }

    /* rxlib-input */
    .rxlib-select-label {
        width: 70% !important;
    }

    .rxlib-select-label-coluna {
        width: 100% !important;
    }

    .rxlib-select-perfil {
        width: 70% !important;
    }

    /* rxlib-table */
    .rxlib-coluna-tabela {
        display: none !important;
    }
}

@media (min-width: 664px) and (max-width: 743px) {

    /* font-size */
    body {
        font-size: 0.95rem !important;
    }

    .dropdown-menu {
        font-size: 0.95rem !important;
    }

    .form-control {
        font-size: 0.95rem !important;
    }

    .form-select {
        font-size: 0.95rem !important;
    }

    /* navbar-top */
    .rxlib-navbar-nav {
        width: 5rem !important;
    }

    .navbar-nav {
        flex-direction: row !important;
    }

    .rxlib-sair {
        margin-left: 1rem !important;
    }

    .navbar-collapse {
        width: 80% !important;
    }

    .navbar-nav .dropdown-menu {
        position: absolute !important;
    }

    .nav-item {
        margin-right: 10px !important;
    }

    .collapse:not(.show) {
        display: initial !important;
    }

    .navbar-toggler {
        display: none !important;
    }

    .rxlib-usuario-logado-navbar {
        display: none !important;
    }

    /* rxlib-select-label-async */
    .rxlib-select-label-async {
        width: 70% !important;
    }

    .rxlib-select-label-async-coluna {
        width: 100% !important;
    }

    .rxlib-select-label-async-input input {
        width: 43% !important;
    }

    .rxlib-select-label-async-coluna .rxlib-select-label-async-input input {
        width: 20% !important;
    }

    .rxlib-select__control {
        height: 2.1rem !important;
        min-height: 2.1rem !important;
    }

    .rxlib-select__single-value {
        top: 46% !important;
    }

    .btn-link-select {
        width: 2.6rem !important;
        height: 2.2rem !important;
    }

    .btn-link-select .fs-3 {
        font-size: calc(1.1rem + .6vw) !important;
    }

    /* rxlib-input */
    .rxlib-select-label {
        width: 70% !important;
    }

    .rxlib-select-label-coluna {
        width: 100% !important;
    }

    .rxlib-select-perfil {
        width: 70% !important;
    }

    /* rxlib-table */
    .rxlib-coluna-tabela {
        display: none !important;
    }
}

@media (min-width: 744px) and (max-width: 759px) {

    /* font-size */
    body {
        font-size: 0.95rem !important;
    }

    .dropdown-menu {
        font-size: 0.95rem !important;
    }

    .form-control {
        font-size: 0.95rem !important;
    }

    .form-select {
        font-size: 0.95rem !important;
    }

    /* navbar-top */
    .rxlib-navbar-nav {
        width: 19rem !important;
    }

    .navbar-nav {
        flex-direction: row !important;
    }

    .rxlib-sair {
        margin-left: 1rem !important;
    }

    .navbar-collapse {
        width: 47% !important;
    }

    .navbar-nav .dropdown-menu {
        position: absolute !important;
    }

    .nav-item {
        margin-right: 10px !important;
    }

    .collapse:not(.show) {
        display: initial !important;
    }

    .navbar-toggler {
        display: none !important;
    }

    /* rxlib-select-label-async */
    .rxlib-select-label-async {
        width: 60% !important;
    }

    .rxlib-select-label-async-coluna {
        width: 100% !important;
    }

    .rxlib-select-label-async-input input {
        width: 43% !important;
    }

    .rxlib-select-label-async-coluna .rxlib-select-label-async-input input {
        width: 22% !important;
    }

    .rxlib-select__control {
        height: 2.1rem !important;
        min-height: 2.1rem !important;
    }

    .rxlib-select__single-value {
        top: 46% !important;
    }

    .btn-link-select {
        width: 2.6rem !important;
        height: 2.2rem !important;
    }

    .btn-link-select .fs-3 {
        font-size: calc(1.1rem + .6vw) !important;
    }

    /* rxlib-input */
    .rxlib-select-label {
        width: 60% !important;
    }

    .rxlib-select-label-coluna {
        width: 100% !important;
    }

    .rxlib-select-perfil {
        width: 60% !important;
    }

    /* rxlib-table */
    .rxlib-coluna-tabela {
        display: none !important;
    }
}

@media (min-width: 760px) and (max-width: 846px) {

    /* font-size */
    body {
        font-size: 0.95rem !important;
    }

    .dropdown-menu {
        font-size: 0.95rem !important;
    }

    .form-control {
        font-size: 0.95rem !important;
    }

    .form-select {
        font-size: 0.95rem !important;
    }

    /* navbar-top */
    .rxlib-navbar-nav {
        width: 20rem !important;
    }

    .navbar-nav {
        flex-direction: row !important;
    }

    .rxlib-sair {
        margin-left: 1rem !important;
    }

    .navbar-collapse {
        width: 50% !important;
    }

    .navbar-nav .dropdown-menu {
        position: absolute !important;
    }

    .nav-item {
        margin-right: 10px !important;
    }

    .collapse:not(.show) {
        display: initial !important;
    }

    .navbar-toggler {
        display: none !important;
    }

    /* rxlib-select-label-async */
    .rxlib-select-label-async {
        width: 60% !important;
    }

    .rxlib-select-label-async-coluna {
        width: 100% !important;
    }

    .rxlib-select-label-async-input input {
        width: 43% !important;
    }

    .rxlib-select-label-async-coluna .rxlib-select-label-async-input input {
        width: 22% !important;
    }

    .rxlib-select__control {
        height: 2.1rem !important;
        min-height: 2.1rem !important;
    }

    .rxlib-select__single-value {
        top: 46% !important;
    }

    .btn-link-select {
        width: 2.6rem !important;
        height: 2.2rem !important;
    }

    .btn-link-select .fs-3 {
        font-size: calc(1.1rem + .6vw) !important;
    }

    /* rxlib-input */
    .rxlib-select-label {
        width: 60% !important;
    }

    .rxlib-select-label-coluna {
        width: 100% !important;
    }

    .rxlib-select-perfil {
        width: 60% !important;
    }
}

@media (min-width: 847px) and (max-width: 881px) {

    /* font-size */
    body {
        font-size: 0.95rem !important;
    }

    .dropdown-menu {
        font-size: 0.95rem !important;
    }

    .form-control {
        font-size: 0.95rem !important;
    }

    .form-select {
        font-size: 0.95rem !important;
    }

    /* navbar-top */
    .rxlib-navbar-nav {
        width: 20rem !important;
    }

    .navbar-nav {
        flex-direction: row !important;
    }

    .rxlib-sair {
        margin-left: 1rem !important;
    }

    .navbar-collapse {
        width: 50% !important;
    }

    .navbar-nav .dropdown-menu {
        position: absolute !important;
    }

    .nav-item {
        margin-right: 10px !important;
    }

    .collapse:not(.show) {
        display: initial !important;
    }

    .navbar-toggler {
        display: none !important;
    }

    /* rxlib-select-label-async */
    .rxlib-select-label-async {
        width: 60% !important;
    }

    .rxlib-select-label-async-coluna {
        width: 100% !important;
    }

    .rxlib-select-label-async-input input {
        width: 44% !important;
    }

    .rxlib-select-label-async-coluna .rxlib-select-label-async-input input {
        width: 23.5% !important;
    }

    .rxlib-select__control {
        height: 2.1rem !important;
        min-height: 2.1rem !important;
    }

    .rxlib-select__single-value {
        top: 46% !important;
    }

    .btn-link-select {
        width: 2.6rem !important;
        height: 2.2rem !important;
    }

    .btn-link-select .fs-3 {
        font-size: calc(1.1rem + .6vw) !important;
    }

    /* rxlib-input */
    .rxlib-select-label {
        width: 60% !important;
    }

    .rxlib-select-label-coluna {
        width: 100% !important;
    }

    .rxlib-select-perfil {
        width: 60% !important;
    }
}

@media (min-width: 882px) and (max-width: 935px) {

    /* navbar-top */
    .rxlib-navbar-nav {
        width: 20rem !important;
    }

    .navbar-nav {
        flex-direction: row !important;
    }

    .rxlib-sair {
        margin-left: 1rem !important;
    }

    .navbar-collapse {
        width: 50% !important;
    }

    .navbar-nav .dropdown-menu {
        position: absolute !important;
    }

    .nav-item {
        margin-right: 10px !important;
    }

    .collapse:not(.show) {
        display: initial !important;
    }

    .navbar-toggler {
        display: none !important;
    }

    /* rxlib-select-label-async */
    .rxlib-select-label-async {
        width: 60% !important;
    }

    .rxlib-select-label-async-coluna {
        width: 100% !important;
    }

    .rxlib-select-label-async-input input {
        width: 44% !important;
    }

    .rxlib-select-label-async-coluna .rxlib-select-label-async-input input {
        width: 23.5% !important;
    }

    .rxlib-select__control {
        height: 2.2rem !important;
        min-height: 2.2rem !important;
    }

    .rxlib-select__single-value {
        top: 46% !important;
    }

    /* rxlib-input */
    .rxlib-select-label {
        width: 60% !important;
    }

    .rxlib-select-label-coluna {
        width: 100% !important;
    }

    .rxlib-select-perfil {
        width: 60% !important;
    }
}

@media (min-width: 936px) and (max-width: 943px) {

    /* navbar-top */
    .rxlib-navbar-nav {
        width: 20rem !important;
    }

    .navbar-nav {
        flex-direction: row !important;
    }

    .rxlib-sair {
        margin-left: 1rem !important;
    }

    .navbar-collapse {
        width: 55% !important;
    }

    .navbar-nav .dropdown-menu {
        position: absolute !important;
    }

    .nav-item {
        margin-right: 10px !important;
    }

    .collapse:not(.show) {
        display: initial !important;
    }

    .navbar-toggler {
        display: none !important;
    }

    /* rxlib-select-label-async */
    .rxlib-select-label-async-coluna .rxlib-select-label-async-input input {
        width: 24% !important;
    }

    .rxlib-select__control {
        height: 2.2rem !important;
        min-height: 2.2rem !important;
    }

    .rxlib-select__single-value {
        top: 46% !important;
    }
}

@media (min-width: 944px) and (max-width: 991px) {

    /* navbar-top */
    .rxlib-navbar-nav {
        width: 20rem !important;
    }

    .navbar-nav {
        flex-direction: row !important;
    }

    .rxlib-sair {
        margin-left: 1rem !important;
    }

    .navbar-collapse {
        width: 60% !important;
    }

    .navbar-nav .dropdown-menu {
        position: absolute !important;
    }

    .nav-item {
        margin-right: 10px !important;
    }

    .collapse:not(.show) {
        display: initial !important;
    }

    .navbar-toggler {
        display: none !important;
    }

    /* rxlib-select-label-async */
    .rxlib-select-label-async-coluna .rxlib-select-label-async-input input {
        width: 24% !important;
    }
}

@media (min-width: 992px) and (max-width: 1062px) {

    /* navbar-top */
    .rxlib-navbar-nav {
        width: 28rem !important;
    }

    .navbar-nav {
        flex-direction: row !important;
    }

    .rxlib-sair {
        margin-left: 1rem !important;
    }

    .navbar-collapse {
        width: 78% !important;
    }

    .navbar-nav .dropdown-menu {
        position: absolute !important;
    }

    .nav-item {
        margin-right: 10px !important;
    }

    .collapse:not(.show) {
        display: initial !important;
    }

    .navbar-toggler {
        display: none !important;
    }

    /* rxlib-select-label-async */
    .rxlib-select-label-async-coluna .rxlib-select-label-async-input input {
        width: 24.5% !important;
    }
}
.rxlib-home-menu {
    margin-top: 4.6rem;
}

.rxlib-home-cards {
    display: flex;
}

.rxlib-home-cards div {
    margin-left: 0.5rem;
}

.rxlib-home-spinner {
    margin-top: 6rem;
}

.rxlib-card-text-muted {
    font-size: 4.5rem;
}

.rxlib-home-data-padrao-expiracao-licenca .rxlib-card-text-muted {
    font-size: 3rem;
    padding-top: 1rem;
}
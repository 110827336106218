.caronte-grid {
    padding-left: 1rem;
    height: calc(100vh - 15rem);
}

.caronte-grid-header {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: bold !important;
    color: var(--rxlib-fonte-grid);
}

.caronte-grid-header-icon {
    padding-right: 5px !important;
}

.MuiDataGrid-columnHeaderTitle {
    font-weight: bold !important;
}

.caronte-grid-header:focus, .MuiDataGrid-cell:focus {
    outline: none !important;
}

.caronte-grid-row-striped {
    background-color: var(--rxlib-telas) !important;
}

.caronte-grid-row-striped-none {
    background-color: var(--rxlib-tabela-listrado-rgb-25) !important;
}

.caronte-grid-row-striped>.MuiDataGrid-row:hover, .MuiDataGrid-row.Mui-hovered {
    background-color: var(--rxlib-telas) !important;
}

.caronte-grid-row-striped-none>.MuiDataGrid-row:hover, .MuiDataGrid-row.Mui-hovered {
    background-color: var(--rxlib-tabela-listrado-rgb-25) !important;
}

.caronte-grid-cell {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 400;
    color: var(--rxlib-fonte-grid);
}

.MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within {
    outline: none !important;
}

.MuiDataGrid-cell {
    border: none !important;
}

.MuiTablePagination-selectLabel {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.MuiTablePagination-displayedRows {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}